import { get, post, del } from "@/utils/https";
import qs from "qs";

// 联系人类型枚举
export function carEvalEnum(bizNo, orderType) {
  const param = {
    bizNo,
    orderType
  };
  return get(
    `/api/v1/common/saas/app/offline/apply/apply/eval/getAssessPicEnum?${qs.stringify(
      param
    )}`
  );
}

// 发起车评估
export function postCarEval(data) {
  if (!data.bizNo) throw "缺少bizNo";
  return post(`/api/v1/common/saas/app/offline/apply/apply/eval`, data);
}

// 单个照片上传(新增/修改)
export function postCarEvalImg(data) {
  return post(
    `/api/v1/common/saas/app/offline/apply/apply/eval/saveAssessAttach`,
    data
  );
}

// 删除附件
export function delCarEvalImg(id) {
  if (!id) throw "缺少id";
  return del(`/api/v1/common/saas/app/offline/apply/apply/eval/remove/${id}`);
}

// 获取已上传的附件
export function getCarEvalImg(bizNo) {
  if (!bizNo) throw "缺少bizNo";
  return get(
    `/api/v1/common/saas/app/offline/apply/apply/eval/getAssessAttach/${bizNo}`
  );
}
