<template>
  <van-popup
    v-model="showPicker"
    class="fill-in-wrap-pop"
    position="right"
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="head">车辆评估</div>
      <div class="content fillin-upload-ctn">
        <div
          v-show="!materialList || !materialList.length"
          class="dis_flex ali_it_cen ju_con_cen m_t_32"
        >
          无需上传影像资料，请发起评估
        </div>
        <div v-for="(field, index) in materialList" :key="index">
          <van-cell
            is-link
            :arrow-direction="field.close ? 'down' : 'up'"
            :value="`已上传${field?.lists?.length ?? 0}张`"
            title-class="fillin-upload-ctn-title"
          >
            <template #title>
              <span v-if="field.isRequired == 'Y'" class="color-r m_r_8"
                >*</span
              >
              <span class="custom-title">{{ field.name }}</span>
            </template>
            <template #label>
              <div class="dis_flex ali_it_cen">
                <span
                  v-if="field.demoUrl"
                  class="custom-title color-blue m_r_16"
                  @click="previewDemo(field.demoUrl)"
                >
                  <van-icon name="eye-o" />
                  示例
                </span>
                <span class="custom-title"
                  >({{ acceptTipsFn(field.attachType) }})</span
                >
              </div>
            </template>
          </van-cell>
          <div class="p_20_32">
            <Upload
              v-model="field.lists"
              previewVali="mobile"
              :key="index"
              :maxCount="field.limit"
              :isDefaultAccept="false"
              :accept="acceptFn(field.attachType)"
              @upload="(param) => uploadCarEvalImg(param, field)"
              @del="delCarEvalImgFn"
            />
          </div>
        </div>
      </div>
      <div class="btns">
        <van-button size="small" @click="showPicker = false">返回</van-button>
        <van-button type="info" size="small" @click="saveFn"
          >发起评估</van-button
        >
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ImagePreview, Notify } from "vant";
import {
  carEvalEnum,
  postCarEval,
  postCarEvalImg,
  delCarEvalImg,
  getCarEvalImg
} from "@/api/carEval";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    // 需要填的表单内容
    detail: {
      type: Object,
      default: () => {}
    },
    unAppoint: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Upload: () => import("@/common/Upload.vue")
  },
  watch: {
    showPicker(v) {
      if (v) {
        // console.log("传入的表单内容", this.fields);
        try {
          this.materialList = [];
          this.init();
        } catch (e) {
          console.log("查看报错", e);
          this.materialList = [];
        }
      } else {
        Notify.clear(); // 关闭弹窗清空
        this.materialList = [];
      }
    },
    // loading
    pageLoading(v) {
      if (v) {
        Notify({
          type: "primary",
          message: "正在为您更新数据信息",
          duration: 1000
        });
      }
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  data() {
    return {
      pageLoading: false,
      materialList: [] // 进件材料上传
    };
  },
  created() {},
  methods: {
    init() {
      this.carEvalEnum();
    },
    // 产品配置
    async carEvalEnum() {
      try {
        this.pageLoading = true;
        const { bizNo } = this.detail;

        // 影像件材料上传数组
        const resp2 = await carEvalEnum(bizNo, "PASSENGER");

        let materialList = (resp2?.data ?? []).map((item) => ({
          ...item,
          lists: [],
          itemCode: item.value,
          attachType: "PIC",
          name: item.displayName,
          isRequired: item.value != "APPEND" ? "Y" : "N",
          limit: item.value != "APPEND" ? 1 : 9
        }));
        this.materialList = [...materialList];

        if (!this.unAppoint) {
          this.materialList = this.materialList.filter(
            (ee) => ee.itemCode != "485"
          );
        }
        await this.getImgList(); // 回填图片信息
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    previewDemo(url) {
      ImagePreview({ images: [url], closeable: true });
    },
    // 获取需要上传的图片列表以后，填值 图片信息
    async getImgList() {
      try {
        this.loading = true;
        let { data } = await getCarEvalImg(this.detail.bizNo);
        if (data && data.length) {
          for (let item of data) {
            const _index = this.materialList.findIndex(
              (sec) => sec.value == item.itemCode
            );
            if (_index >= 0) {
              this.materialList[_index].lists.push({
                ...item,
                rowId: item.id,
                fileUrl: item.attachUrl,
                fileName: item.attachName
              });
            }
          }
        } else {
          this.materialList = this.materialList.map((item) => {
            item.lists = [];
            return item;
          });
        }

        // console.log("查看回填以后得图片数组", this.materialList);
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    async saveFn() {
      try {
        const { vehicleVin, bizNo } = this.detail;

        if (this.materialList && this.materialList.length) {
          // 如果本身有需要填的材料，则需要校验一下
          let empty = this.materialList
            .filter((e) => e.value != "APPEND")
            .some((e) => !e.lists || e.lists.length == 0);
          if (empty) throw "有图片资料未上传";
        }

        this.$toast.loading({
          message: "数据提交中……",
          duration: 0,
          forbidClick: true
        });
        await postCarEval({
          bizNo: bizNo,
          orderType: "PASSENGER",
          vin: vehicleVin
        });

        this.$emit("update", this.form);
        this.$toast.clear();
        this.showPicker = false;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 图片上传成功，现在开始传到车评估图片库
    async uploadCarEvalImg(param, field) {
      try {
        const { name, url } = param;
        const { vehicleVin, bizNo } = this.detail;
        Notify.clear();
        await postCarEvalImg({
          attachName: name,
          attachUrl: url,
          bizNo: bizNo,
          itemCode: field.itemCode,
          vehicleVin: vehicleVin
        });
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.init();
      }
    },
    // 图片删除成功，现在开始传到车评估图片库
    async delCarEvalImgFn(index, item) {
      try {
        Notify.clear();
        await delCarEvalImg(item.id);
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.init();
      }
    },
    acceptFn(fileType) {
      if (fileType == "PIC") {
        return ".jpg, .jpeg, .png";
      } else if (fileType == "VIDEO") {
        return "video/*";
      } else if (fileType == "DOC") {
        return ".pdf";
      } else {
        return "image/*,video/*";
      }
    },
    acceptTipsFn(fileType) {
      if (fileType == "PIC") {
        return "请上传.jpg, .jpeg, .png格式文件";
      } else if (fileType == "VIDEO") {
        return "请上传视频格式文件";
      } else if (fileType == "DOC") {
        return "请上传pdf格式文件";
      } else {
        return "image/*,video/*";
      }
    },
    clickPreviewFn() {}
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap-pop {
  :deep(.fill-in-wrap) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .head {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #efefef;
    }
    .content {
      flex: 1;
      overflow-y: auto;

      .color-r {
        color: red;
      }
      .color-blue {
        color: rgb(24, 144, 255);
      }
    }
    .fillin-upload-ctn-title {
      min-width: 65vw;
    }
    .btns {
      border-top: 1px solid #efefef;
      height: 112px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
